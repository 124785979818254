import React from "react"
import FooterComponent from "./FooterComponent"
import { StaticQuery, graphql } from "gatsby"

export default ({ isPreview }) => {
  if (isPreview) {
    return <FooterComponent />
  }

  return (
    <StaticQuery
      query={graphql`
        query {
          campaigns: allMarkdownRemark(
            filter: { frontmatter: { templateKey: { eq: "campaign" } } }
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            edges {
              node {
                id
                fields {
                  slug
                }
                frontmatter {
                  title
                  show
                }
              }
            }
          }
        }
      `}
    >
      {data => {
        const { campaigns } = data
        return <FooterComponent campaigns={campaigns} />
      }}
    </StaticQuery>
  )
}
