import React from "react"

import styles from "./styles.module.css"
import { Link } from "gatsby"

const Website = ({ url }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    {url.replace("https://", "")}
  </a>
)

const NavItem = ({ url, imgPath, name }) => (
  <a href={url} target="_blank" rel="noopener noreferrer">
    <div
      style={{
        backgroundImage: `url(${imgPath})`,
      }}
      alt={`{name} Logo Icon`}
      className={styles.icon}
    />
    {name}
  </a>
)

const Address = () => (
  <div className={styles.card}>
    <img
      src={require("../../img/address.png")}
      alt="address-icon"
      loading="lazy"
    />
    <span className={styles.cardContent}>
      <p className={styles.title}>Morada:</p>
      <p>Edifício Centro Campanhã,</p>
      <p>Rua de Justino Teixeira,</p>
      <p>2º Andar, Escritório 214</p>
      <p>4300-273 Porto – Portugal</p>
    </span>
  </div>
)

const Contact = ({ imgName, alt, label, value }) => (
  <div className={styles.subsection}>
    <img src={require(`../../img/${imgName}.png`)} alt={alt} loading="lazy" />
    <span className={styles.cardContent}>
      <p className={styles.title}>{label}</p>
      <p>{value}</p>
    </span>
  </div>
)

const Politics = () => (
  <div className={styles.politics}>
    <div className={styles.content}>
      <Link to="/termos-e-condicoes">Termos e Condições</Link>
      <Link to="/politica-privacidade">Política de Privacidade</Link>
      <Link to="/politica-campanhas">Política de Campanhas</Link>
    </div>
  </div>
)

const Campaigns = ({ campaigns }) => (
  <div className={styles.politics}>
    <div className={styles.content}>
      {campaigns.edges
        .filter(({ node }) => node.frontmatter.show)
        .map(({ node }) => (
          <Link to={node.fields.slug} key={node.id}>
            {node.frontmatter.title}
          </Link>
        ))}
    </div>
  </div>
)

const Partners = () => (
  <div className={`${styles.content} ${styles.supports}`}>
    <a
      target="_blank"
      href="https://www.facebook.com/cloudware"
      rel="noopener noreferrer"
    >
      <span className={styles.social}>
        <img
          src={require("../../img/facebook.png")}
          alt="Facebook Logo"
          loading="lazy"
        />
      </span>
    </a>
    <a
      target="_blank"
      href="https://www.linkedin.com/company/cloudware-s-a-/"
      rel="noopener noreferrer"
    >
      <span className={styles.social}>
        <img
          src={require("../../img/linkedin.png")}
          alt="Linkedin Logo"
          loading="lazy"
        />
      </span>
    </a>
    <a
      target="_blank"
      href="mailto:info@cloudware.pt"
      rel="noopener noreferrer"
    >
      <span className={styles.social}>
        <img
          src={require("../../img/gmail.png")}
          alt="Gmail Logo"
          loading="lazy"
        />
      </span>
    </a>
    <img
      src={require("../../img/Norte-2020.png")}
      alt="Norte 2020 Logo"
      loading="lazy"
    />
    <Link to="/casper-2020" className={styles.casper}>
      Casper 2020 - Ver detalhes
    </Link>
    <img
      style={{ width: 25, height: 30 }}
      src={require("../../img/PME_Lider_2019_2D_cores.png")}
      alt="PME Lider 2019 Logo"
      loading="lazy"
    />
    <img
      style={{  height: 30 }}
      src={require("../../img/pme-excelencia.png")}
      alt="PME Excelência 2020"
      loading="lazy"
    />
    <img
      style={{ width: 30, height: 30 }}
      src={require("../../img/13298_ParceiroAplauso2020_CMYK_AF.png")}
      alt="Parceiro Aplauso 2020"
      loading="lazy"
    />
  </div>
)

const FooterComponent = ({ campaigns }) => (
  <footer className={styles.footer}>
    <div className={styles.content}>
      <div className={`${styles.card} ${styles.column}`}>
        <Address />
      </div>
      <div className={`${styles.card} ${styles.column}`}>
        <Contact
          imgName="phone"
          alt="Telefone"
          label="Telefone: "
          value="(+351) 227 660 200"
        />
        <Contact
          imgName="email"
          alt="Email"
          label="Email: "
          value="info@cloudware.pt"
        />
      </div>

      <div className={styles.card}>
        <img
          src={require("../../img/website.png")}
          alt="address-icon"
          loading="lazy"
        />
        <span className={styles.cardContent}>
          <p className={styles.title}>Website:</p>
          <Website url="https://cloudware.pt" />
          <Website url="https://business.cloudware.pt" />
          <Website url="https://learning.cloudware.pt/" />
          {/* <Website url="http://www.app.cloudware.pt" />
          <Website url="http://www.pos.cloudware.pt" /> */}
        </span>
      </div>

      <div className={`${styles.card} ${styles.menu}`}>
        <span className={styles.separator}></span>
        <span className={styles.cardContent}>
          <NavItem
            url="https://cloudware.pt"
            imgPath={require("../../img/Cloudware_Branco.png")}
            name="Cloudware"
          />
          <NavItem
            url="https://business.cloudware.pt"
            imgPath={require("../../img/Cloudware_Business_Branco.png")}
            name=" Cloudware Business"
          />
          <NavItem
            url="https://learning.cloudware.pt"
            imgPath={require("../../img/Cloudware_Learning_Center_Branco.png")}
            name="Cloudware Learning Center"
          />
          <NavItem
            url="https://cloudware.pt/toconline"
            imgPath={require("../../img/toconline-branco-transparente.png")}
            name="TOConline"
          />

          <Link to="/blog">
            <div
              style={{
                backgroundImage: `url(${require("../../img/social-rss.png")})`,
              }}
              alt="Blog Logo Icon"
              className={styles.icon}
            />
            Blog
          </Link>
        </span>
      </div>
    </div>
    <Politics />
    {campaigns && !!campaigns.edges.length && (
      <Campaigns campaigns={campaigns} />
    )}
    <Partners />
  </footer>
)

export default FooterComponent
