/**
 * SimpleLayout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "../components/Navbar/Navbar"
import Footer from "../components/Footer/Footer"
import CookiesBanner from "./CookiesBanner"

import "./layout.css"

const SimpleLayout = ({ children, path, isPreview }) => {
  return (
    <>
      <CookiesBanner />
      <Navbar path={path} />
      <main>{children}</main>
      <Footer isPreview={isPreview}/>
    </>
  )
}

SimpleLayout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.any,
}

export default SimpleLayout
