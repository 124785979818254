import React from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"

export default () => (
  <CookieConsent
    location="bottom"
    buttonText="Ok"
    style={{
      background: "rgba(0, 0, 0, 0.6)",
      fontSize: 14,
      fontFamily: "Libre Franklin",
      textAlign: "center",
      zIndex: 10000
    }}
    buttonStyle={{
      backgroundColor: "#4ebcbc",
      color: "white",
      fontSize: "13px",
    }}
    expires={150}
  >
    Ao continuar a navegar no site, concorda com o uso de cookies de acordo com
    nossa{" "}
    <Link
      to="/politica-privacidade"
      style={{ color: "white", textDecoration: "underline" }}
    >
      Política de Privacidade
    </Link>
    .
  </CookieConsent>
)
